<template>
  <div>
    <b-table
      small
      :items="rows"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      foot-clone
      striped
    >
      <template #head(ecpm)>
        {{ $t('stats.spent') }}
      </template>

      <template #head(show)>
        {{ $t('stats.shows') }}
      </template>

      <template #head(click)>
        {{ $t('stats.clicks') }}
      </template>

      <template #cell(show)="data">
        {{ data.value ? data.value : 0 }}
      </template>

      <template #cell(click)="data">
        {{ data.value ? data.value : 0 }}
      </template>

      <template #cell(ecpm)="data">
        {{ currency('USD', data.value) }}
      </template>

      <template #foot(datetime)>
        <strong>{{ $t('Records') }}: {{ rows.length }}</strong>
      </template>

      <template #foot(click)>
        {{ totalFormat('click').toLocaleString('Ru-ru') }}
      </template>

      <template #foot(show)>
        {{ totalFormat('show').toLocaleString('Ru-ru') }}
      </template>

      <template #foot(ecpm)>
        {{ currency('USD', totalAmount) }}
      </template>
    </b-table>
  </div>
</template>
<script>
import {
  BTable,
} from 'bootstrap-vue'
import currencyFormatter from '@/utils/currency-formatter'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'

export default {
  components: {
    BTable,
  },
  props: {
    rows: {
      type: Array,
      deep: true,
      required: true,
      deafult: () => [],
    },
    fields: {
      type: Array,
      required: true,
      deafult: () => [],
    },
    range: {
      type: [String, Array],
      required: true,
      deafult: () => [],
    },
  },
  data() {
    return {
      compaignsRows: [],
      campaigns: [],
      sortBy: 'datetime',
      sortDesc: true,
      sortDirection: 'asc',
      getFormattedDate,
      getFormattedDateTime,
      currency: currencyFormatter,
      rangeDefault: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    totalAmount() {
      let totalAmount = 0
      this.rows.forEach(i => {
        totalAmount += i.ecpm
      })
      return totalAmount
    },
  },
  methods: {
    totalFormat(field) {
      let total = 0
      this.rows.forEach(i => {
        total += i[field]
      })
      return total
    },
  },
}
</script>
