<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <PrebidPartnerToolbar
        v-if="Object.keys(prebidpartner).length !== 0"
        :prebidpartner="prebidpartner"
        closing
      />
      <hr>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h4>{{ $t('Statistics') }}</h4>
        </div>
        <div class="d-flex align-items-center">
          <div
            class="col-picker"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
                @on-close="toggleRange"
              />
            </div>
          </div>
          <div v-if="authUserData.role.includes('admin')">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              size="sm"
              class="btn-icon ml-25"
              :to="{ name: 'dashboard-prebidpartner-stats-v2' }"
            >
              V2
            </b-button>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else-if="rows.length !== 0">
        <TableStats
          :fields="fields"
          :range="rangePicker"
          :rows="rows"
        />
      </div>
      <div
        v-else
        class="alert-body  text-center"
      >
        <span>{{ $t('No data for this period') }}</span>
      </div>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard,
  BSpinner,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import getFormattedDate, { getFormattedDateTime } from '@/utils/date-formatter'
import TableStats from './TableStats.vue'
import PrebidPartnerToolbar from './PrebidPartnerToolbar.vue'

export default {
  components: {
    Breadcrumbs,
    BCard,
    BSpinner,
    BButton,
    TableStats,
    flatPickr,
    PrebidPartnerToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: false,
      pageTitle: '',
      pageSubtitle: '',
      breadcrumbItems: [],
      prebidpartner: {},
      rows: [],
      authUserData: getUserData(),
      getFormattedDate,
      getFormattedDateTime,
      fields: [
        { key: 'datetime', label: 'Date' },
        { key: 'show', label: 'Shows' },
        { key: 'click', label: 'Clicks' },
        { key: 'ecpm', label: 'Ecpm' },
      ],
      rangePicker: [],
      rangeDefault: [getFormattedDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)), getFormattedDate(new Date())],
    }
  },
  computed: {
    selectedRangeFormated() {
      let dateFrom
      let dateTo
      const selectedRange = this.rangePicker
      if (Array.isArray(selectedRange)) {
        [dateFrom, dateTo] = selectedRange
      } else {
        [dateFrom, , dateTo] = selectedRange.split(' ')
      }

      return {
        from: getFormattedDate(new Date(dateFrom)),
        to: dateTo === undefined ? getFormattedDate(new Date(dateFrom)) : getFormattedDate(new Date(dateTo)),
      }
    },
  },
  async created() {
    this.rangePicker = this.rangeDefault
    this.getPrebidPartnerData()
  },
  methods: {
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$t('Prebid partners'),
          to: '/prebidpartners/',
        },
        {
          text: this.prebidpartner.name,
          to: `/prebidpartners/${this.prebidpartner._id}`,
        },
        {
          text: this.$t('Statistics'),
          active: true,
        },
      ]
    },
    async getPrebidPartnerData() {
      const responseData = await useJwt.getPrebidPartner(this.$route.params.id)
      this.prebidpartner = responseData.data.prebidpartner || []
      this.getPrebidPartnerStats()
      this.setBreabcrumbs()
    },
    async getPrebidPartnerStats() {
      this.loading = true
      this.rows = []

      const responseData = await useJwt.getPrebidPartnerStatsByDate(this.$route.params.id, this.selectedRangeFormated)
      this.rows = responseData.data.prebidpartnerstats || []

      this.loading = false
    },
    toggleRange(e) {
      this.rangePicker = e
      this.getPrebidPartnerStats()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
  .table-filters {
    display: flex;
    justify-content: space-between;
  }

  tfoot th {
    background-color: #fff!important;
  }

  .col-picker {
    min-width: 220px;
  }

  @media (max-width: 1400px) {
    .table-filters {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }

    .col-type {
      width: 60%;
    }
  }

  @media (max-width: 992px) {
    .col-type {
      width: 70%;
    }
  }

  @media (max-width: 800px) {
    .col-type {
      width: 100%;
    }
  }
</style>
